import React, { useState, useEffect } from 'react';
import { StaticQuery, graphql } from 'gatsby';
import { useQuery } from 'react-apollo';
import { AlertsConsumer } from '../../../contexts/Alerts';
import { Link, navigate } from '@reach/router';
import styles from './styles.module.scss';
import gql from 'graphql-tag';
import Tooltip from 'src/components/Tooltip';
import axios from 'axios';
import Cookies from 'js-cookie';
import LoadingSpinner from 'src/components/LoadingSpinner';
import { ChevronRight } from 'react-feather';
import tyfrImage from 'src/images/tyfr.svg';

import {
  Calendar,
  Home,
  User,
  Send,
  Settings,
  Zap,
  Activity,
  MessageSquare,
  Book,
  Frown,
} from 'react-feather';

const GET_RESERVATION_DATA = gql`
  query GuestReservationFormToken($reference: String) {
    guestReservation(where: { reference: $reference }) {
      reference
      reservationFormToken {
        token
      }
    }
  }
`;

const NavMenu = ({ isMenuCollapsed, role, scrollPosition }) => {
  const userAuthenticated = role !== 'ANONYMOUS';

  const [reportRoutes, setReportRoutes] = useState(null);
  const [loading, setIsLoading] = useState(true);
  const [tyfrReference, setTyfrReference] = useState('');
  const [tyfrReferenceSubmitted, setTyfrReferenceSubmitted] = useState(false);

  const { data, error, loading: queryLoading, refetch, ...rest } = useQuery(
    GET_RESERVATION_DATA,
    {
      skip: tyfrReferenceSubmitted == false,
      variables: { reference: tyfrReference },
      fetchPolicy: 'no-cache',
    }
  );

  useEffect(() => {
    if (!data) return;
    if (!data.guestReservation) {
      alert(`Reservation ${tyfrReference} not found.`);
      setTyfrReferenceSubmitted(false);
      return;
    }
    // console.log('data', data.guestReservation); // TODO: Open window and clear related state
    //   console.log('data', data.guestReservation.reservationFormToken.token); // TODO: Open window and clear related state
    window.open(
      `https://www.secureinformationcollection.com/webforms/tyfr?reference=${tyfrReference}&token=${encodeURIComponent(
        data.guestReservation.reservationFormToken.token
      )}`
    );
    setTyfrReference('');
    setTyfrReferenceSubmitted(false);
  }, [data]);

  // const [openSelfFinalizationForm, openSelfFinalizationFormResponse] = useQuery(
  //   GET_RESERVATION_DATA,
  //   {

  //     variables: { reference: tyfrReference },
  //     fetchPolicy: 'no-cache',
  //   }
  // );

  // useEffect (() => {
  //   openSelfFinalizationFormCallback();
  // }, [tyfrReferenceSubmitted]);

  // const openSelfFinalizationFormCallback = React.useCallback(
  //   data => {
  //     //  axios.post('myapi/blog/123', { text: newText }).catch(console.error);
  //     console.log('tyfrReferenceSubmitted', tyfrReferenceSubmitted);
  //     console.log('tyfrReference', tyfrReference);
  //     // if (data.variables.notes != notes) {
  //     //   console.log('UPDATING');
  //     //   updateExceptionNotes(data);
  //     // }
  //   },
  //   [tyfrReferenceSubmitted]
  // );

  // useEffect(() => {
  //   $('#accordion .collapse').collapse({
  //     toggle: true,
  //   });
  //   return () => {
  //     $('#accordion .collapse').collapse('dispose');
  //   };
  // }, []);
  useEffect(() => {
    let token = new URLSearchParams(window.location.hash.substr(1)).get(
      'token'
    );
    if (token) {
      Cookies.set('token', token);
      window.location.replace(window.location.href.replace(/\#.*/, ''));
    } else {
      token = Cookies.get('token');
    }

    const getReportLabel = async () => {
      try {
        const result = await axios.get(`${process.env.BHIP_API_URL}/reports/`, {
          headers: { Authorization: `Bearer: ${token}` },
        });

        const resultData = result.data;
        setIsLoading(false);
        setReportRoutes(resultData?.routes);
      } catch (err) {
        setIsLoading(false);
      }

      // const routes = result?.data?.routes;

      // if (resultData.error) {
      //   if (resultData.error == 'ACCESS_DENIED') {
      //     console.log('result3', result);
      //     window.location.href = '/login';
      //     return;
      //   }
      // }
      // const foundMatch =
      //   routes && routes.find(route => route.slug === location.pathname);
      // if (foundMatch) {
      //   setPageLabel(foundMatch.label);
      // }
    };
    getReportLabel();
  }, []);
  const linkStyles = `d-inline-flex ${
    isMenuCollapsed ? 'justify-content-center' : 'pl-4'
  } align-items-center w-100 text-left nav-link ${isMenuCollapsed &&
    styles.collapsedLink}`;

  const menuItem = `${
    isMenuCollapsed ? 'justify-content-center' : 'pl-4 py-2 pr-1 text-primary'
  }`;

  const isActive = ({ isCurrent }) => {
    return isCurrent
      ? {
          className: `${linkStyles} ${styles.activePage}`,
        }
      : null;
  };
  const toolTip = {};
  if (isMenuCollapsed) {
    toolTip['data-tooltip'] = 'true';
  }

  const AlertsLoading = ({ isLoading, width, height, position }) => {
    let style = {};
    if (position) {
      style.right = position;
    }

    return (
      <span className="ml-2 position-absolute d-inline" style={style}>
        {isLoading && (
          <LoadingSpinner
            customDimensions={{ width: '18px', height: '18px' }}
            lightenColor={true}
          />
        )}
      </span>
    );
  };

  return (
    <>
      <div id="accordion" className={`${styles.accordionNav}`}>
        <div className="_card">
          {!isMenuCollapsed && (
            <div className="_card-header" id="headingOne">
              <h5 className="mb-0">
                <button
                  className={`navAccordionLink  btn btn-link pl-1 pt-2 pb-0 d-flex align-items-center`}
                  data-toggle="collapse"
                  data-target="#collapseOne"
                  aria-expanded="true"
                  aria-controls="collapseOne"
                  style={{ color: '#212529', fontSize: '.8rem', width: '100%' }}
                >
                  Features
                </button>
              </h5>
            </div>
          )}

          <div
            id="collapseOne"
            className={`collapse show ${isMenuCollapsed && 'show'}`}
            aria-labelledby="headingOne"
          >
            <div className="_card-body">
              <ul
                className={`${styles.listUnstyled} list-unstyled mt-1 mb-0 small`}
              >
                {/* {!isMenuCollapsed && (
                  <li className={`${styles.navTitle} pl-3 pt-2 pb-2`}>
                    Features
                  </li>
                )} */}
                <li className={`${styles.calendarSubmenu}`}>
                  <AlertsConsumer>
                    {({ state: alertsState, loading: alertsLoading }) => {
                      const calendarAlerts =
                        alertsState.calendarRedAlerts &&
                        alertsState.calendarRedAlerts.length > 0
                          ? alertsState.calendarRedAlerts.length
                          : null;
                      const showIcon =
                        !isMenuCollapsed || (isMenuCollapsed && !alertsLoading);

                      return (
                        <Tooltip
                          label={
                            calendarAlerts
                              ? `Calendar 
                          (${calendarAlerts} Alerts)`
                              : 'Calendar'
                          }
                          placement="right"
                          id={`calendar-tooltip`}
                          hide={!isMenuCollapsed}
                          // className="d-none"
                        >
                          <Link
                            // title="Calendar"
                            onClick={() => {

                              document
                                .querySelector('body')
                                .classList.remove('offcanvas-open');
                            }}
                            className={`${linkStyles} ${isMenuCollapsed &&
                              styles.collapsedLink}`}
                            // to={`/calendar?start=${moment().format('YYYYMMDD')}`}
                            to={`/calendar`}
                            state={{ isMenuCollapsed, scrollPosition }}
                            getProps={isActive}
                          >
                            <div>
                              <div className="position-relative">
                                <Calendar
                                  className={`${!showIcon &&
                                    alertsLoading &&
                                    'invisible'}`}
                                  size="15"
                                />

                                <AlertsLoading
                                  isLoading={isMenuCollapsed && alertsLoading}
                                  position={'-2px'}
                                />

                                {isMenuCollapsed &&
                                  alertsState.calendarRedAlerts &&
                                  alertsState.calendarRedAlerts.length > 0 &&
                                  !loading && (
                                    <span className="badge badge-danger badge-collapsed ml-2 position-absolute"></span>
                                  )}
                                <span className=" pl-1">Calendar</span>

                                {alertsState.calendarRedAlerts &&
                                  alertsState.calendarRedAlerts.length > 0 && (
                                    <span className="badge badge-danger ml-2">
                                      {alertsState.calendarRedAlerts.length}
                                    </span>
                                  )}
                                <AlertsLoading
                                  isLoading={!isMenuCollapsed && alertsLoading}
                                />
                              </div>
                            </div>
                          </Link>
                        </Tooltip>
                      );
                    }}
                  </AlertsConsumer>
                </li>
                <li>
                  <Tooltip
                    label="Properties"
                    placement="right"
                    id={`properties-tooltip`}
                    hide={!isMenuCollapsed}
                    // className="d-none"
                  >
                    <Link
                      onClick={() => {
                        document
                          .querySelector('body')
                          .classList.remove('offcanvas-open');
                      }}
                      className={`${linkStyles} ${isMenuCollapsed &&
                        styles.collapsedLink}`}
                      // title="Properties"
                      to="/properties"
                      getProps={isActive}
                      state={{ isMenuCollapsed, scrollPosition }}
                    >
                      <div>
                        <Home size="15" />
                        <span className=" pl-1">Properties</span>
                      </div>
                    </Link>
                  </Tooltip>
                </li>
                <li>
                  <Tooltip
                    label="Guest Relations"
                    placement="right"
                    id={`guest-tooltip`}
                    hide={!isMenuCollapsed}
                    // className="d-none"
                  >
                    <Link
                      // title="Guest Relations"
                      onClick={() => {
                        document
                          .querySelector('body')
                          .classList.remove('offcanvas-open');
                      }}
                      className={`${linkStyles} ${isMenuCollapsed &&
                        styles.collapsedLink}`}
                      to="/guests"
                      getProps={isActive}
                      state={{ isMenuCollapsed, scrollPosition }}
                    >
                      <div>
                        <User size="15" />
                        <span className=" pl-1">Guest Relations</span>
                      </div>
                    </Link>
                  </Tooltip>
                </li>

                <li>
                  <AlertsConsumer>
                    {({ state: alertsState, loading: alertsLoading }) => {
                      const guestLeadsAlerts =
                        alertsState.guestLeads &&
                        alertsState.guestLeads.length > 0
                          ? alertsState.guestLeads.length
                          : null;
                      const showIcon =
                        !isMenuCollapsed || (isMenuCollapsed && !alertsLoading);
                      return (
                        <Tooltip
                          label={
                            guestLeadsAlerts
                              ? `Leads 
                    (${guestLeadsAlerts} Alerts)`
                              : 'Leads'
                          }
                          placement="right"
                          id={`leads-tooltip`}
                          hide={!isMenuCollapsed}
                          // className="d-none"
                        >
                          <Link
                            // title="Leads"
                            onClick={() => {
                              document
                                .querySelector('body')
                                .classList.remove('offcanvas-open');
                            }}
                            className={`${linkStyles} ${isMenuCollapsed &&
                              styles.collapsedLink}`}
                            to="/leads"
                            getProps={isActive}
                            state={{ isMenuCollapsed, scrollPosition }}
                          >
                            <div>
                              <div className="position-relative">
                                <Send
                                  className={`${!showIcon &&
                                    alertsLoading &&
                                    'invisible'}`}
                                  size="15"
                                />
                                <AlertsLoading
                                  isLoading={isMenuCollapsed && alertsLoading}
                                  position={'-2px'}
                                />
                                {isMenuCollapsed &&
                                  alertsState.guestLeads &&
                                  alertsState.guestLeads.length > 0 && (
                                    <span className="badge badge-danger badge-collapsed ml-2 position-absolute"></span>
                                  )}
                                <span className=" pl-1">Leads</span>
                                {alertsState.guestLeads &&
                                  alertsState.guestLeads.length > 0 && (
                                    <span className="badge badge-danger ml-2">
                                      {alertsState.guestLeads.length}
                                    </span>
                                  )}
                                <AlertsLoading
                                  isLoading={!isMenuCollapsed && alertsLoading}
                                />
                              </div>
                            </div>
                          </Link>
                        </Tooltip>
                      );
                    }}
                  </AlertsConsumer>
                </li>
                <li>
                  <Tooltip
                    label={'Reservations'}
                    placement="right"
                    id={`reservations-tooltip`}
                    hide={!isMenuCollapsed}
                    // className="d-none"
                  >
                    <Link
                      // title="Leads"
                      onClick={() => {
                        document
                          .querySelector('body')
                          .classList.remove('offcanvas-open');
                      }}
                      className={`${linkStyles} ${isMenuCollapsed &&
                        styles.collapsedLink}`}
                      to="/reservations"
                      getProps={isActive}
                      state={{ isMenuCollapsed, scrollPosition }}
                    >
                      <div>
                        <div className="position-relative">
                          <Book size="15" />
                          {/* {isMenuCollapsed &&
                    alertsState.guestLeads &&
                    alertsState.guestLeads.length > 0 && (
                      <span className="badge badge-danger badge-collapsed ml-2 position-absolute"></span>
                    )} */}
                          <span className=" pl-1">Reservations</span>
                        </div>
                      </div>
                    </Link>
                  </Tooltip>
                </li>
                <li>
                  <Tooltip
                    label={'Resort Request'}
                    placement="right"
                    id={`resort-request-tooltip`}
                    hide={!isMenuCollapsed}
                    // className="d-none"
                  >
                    <Link
                      // title="Leads"
                      onClick={() => {
                        document
                          .querySelector('body')
                          .classList.remove('offcanvas-open');
                      }}
                      className={`${linkStyles} ${isMenuCollapsed &&
                        styles.collapsedLink}`}
                      to="/resort-request"
                      getProps={isActive}
                      state={{ isMenuCollapsed, scrollPosition }}
                    >
                      <div>
                        <div className="position-relative">
                          {/* <Book size="15" /> */}
                          {/* <img src={resortRequest} width="15" /> */}

                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="15"
                            height="15"
                            viewBox="0 0 15 15"
                            id="resort-request"
                            // stroke="currentColor"
                          >
                            <g
                              id="Group_4"
                              data-name="Group 4"
                              transform="translate(-157 -371)"
                            >
                              <rect
                                id="Rectangle_8"
                                data-name="Rectangle 8"
                                width="15"
                                height="15"
                                transform="translate(157 371)"
                                fill="none"
                              />
                              <g
                                id="Group_3"
                                data-name="Group 3"
                                transform="translate(-0.3)"
                              >
                                <path
                                  id="building"
                                  d="M15.053,16.559V4.507A1.507,1.507,0,0,0,13.546,3H6.013A1.507,1.507,0,0,0,4.507,4.507V16.559m10.546,0h1.507m-1.507,0H11.286m-6.78,0H3m1.507,0H8.273m0,0V12.793a.753.753,0,0,1,.753-.753h1.507a.753.753,0,0,1,.753.753v3.766m-3.013,0h3.013"
                                  transform="translate(155 368.735)"
                                  fill="none"
                                  stroke="#0569b4"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth="1.25"
                                />
                                <path
                                  id="Union_1"
                                  data-name="Union 1"
                                  d="M3.733,3.733a.933.933,0,1,1,.933.933A.933.933,0,0,1,3.733,3.733ZM0,3.733a.933.933,0,1,1,.933.933A.933.933,0,0,1,0,3.733ZM3.733.933a.933.933,0,1,1,.933.933A.933.933,0,0,1,3.733.933ZM0,.933a.933.933,0,1,1,.933.933A.933.933,0,0,1,0,.933Z"
                                  transform="translate(161.92 373.849)"
                                  fill="#0569b4"
                                />
                              </g>
                            </g>
                          </svg>
                          {/* {isMenuCollapsed &&
                    alertsState.guestLeads &&
                    alertsState.guestLeads.length > 0 && (
                      <span className="badge badge-danger badge-collapsed ml-2 position-absolute"></span>
                    )} */}
                          <span className=" pl-1">Resort Request</span>
                        </div>
                      </div>
                    </Link>
                  </Tooltip>
                </li>
                <li>
                  <Tooltip
                    label="Employees"
                    placement="right"
                    id={`employees-tooltip`}
                    hide={!isMenuCollapsed}
                    // className="d-none"
                  >
                    <Link
                      // title="Employees"
                      onClick={() => {
                        document
                          .querySelector('body')
                          .classList.remove('offcanvas-open');
                      }}
                      className={`${linkStyles} ${isMenuCollapsed &&
                        styles.collapsedLink}`}
                      to="/employees"
                      getProps={isActive}
                      state={{ isMenuCollapsed, scrollPosition }}
                    >
                      <div>
                        <User size="15" />
                        <span className=" pl-1">Employees</span>
                      </div>
                    </Link>
                  </Tooltip>
                </li>
                <li>
                  <Tooltip
                    label="Settings"
                    placement="right"
                    id={`settings-tooltip`}
                    hide={!isMenuCollapsed}
                    // className="d-none"
                  >
                    <Link
                      // title="Settings"
                      onClick={() => {
                        document
                          .querySelector('body')
                          .classList.remove('offcanvas-open');
                      }}
                      className={`${linkStyles} ${isMenuCollapsed &&
                        styles.collapsedLink}`}
                      to="/settings"
                      getProps={isActive}
                      state={{ isMenuCollapsed, scrollPosition }}
                    >
                      <div>
                        <Settings size="15" />
                        <span className=" pl-1">Settings</span>
                      </div>
                    </Link>
                  </Tooltip>
                </li>
                <li>
                  <Tooltip
                    label={'Report an Issue'}
                    placement="right"
                    id={`rai-tooltip`}
                    hide={!isMenuCollapsed}
                    // className="d-none"
                  >
                    <a
                      // title="Report an Issue"
                      className={`${linkStyles} ${isMenuCollapsed &&
                        styles.collapsedLink}`}
                      href="https://gitlab.bhipdevelopment.com/bhip/bhip-dashboard/issues/new?nav_source=navbar"
                    >
                      <div>
                        <MessageSquare size="15" />
                        <span className="link-label pl-1">Report an Issue</span>
                      </div>
                    </a>
                  </Tooltip>
                </li>
                <li className={`${isMenuCollapsed ? 'd-none' : ''}`}>
                  <div
                    // title="Report an Issue"
                    className={`${menuItem} `}
                  >
                    <div>
                      <img src={tyfrImage} width="15" />
                      <span className="link-label pl-1">Self Finalization</span>
                    </div>
                    <form
                      onSubmit={event => {
                        event.preventDefault();
                        setTyfrReferenceSubmitted(true);
                      }}
                    >
                      <input
                        className="form-control mt-1"
                        style={{ height: '1.8rem' }}
                        name="tyfrReference"
                        placeholder="HA-123456"
                        value={tyfrReference}
                        onChange={event => setTyfrReference(event.target.value)}
                      />
                    </form>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="_card">
          {!isMenuCollapsed && (
            <div className="_card-header" id="headingTwo">
              <h5 className="mb-0">
                <button
                  className="navAccordionLink navAccordionLink  btn btn-link pl-1 pt-2 pb-0 d-flex align-items-center"
                  data-toggle="collapse"
                  data-target="#collapseTwo"
                  aria-expanded="false"
                  aria-controls="collapseTwo"
                  style={{ color: '#212529', fontSize: '.8rem' }}
                >
                  Customer Service Reports
                </button>
              </h5>
            </div>
          )}
          <div
            id="collapseTwo"
            className={`collapse show ${isMenuCollapsed && 'show'}`}
            aria-labelledby="headingTwo"
          >
            <div className="_card-body">
              <ul
                className={`${styles.listUnstyled} list-unstyled  mb-0 components small`}
              >
                {/* {!isMenuCollapsed && (
                  <li className={`${styles.navTitle} mt-3 pl-3 pt-2 pb-2`}>
                    Customer Service Reports
                  </li>
                )} */}
                <li>
                  <Tooltip
                    label={`Pending Reviews`}
                    placement="right"
                    id={`ds-tooltip`}
                    hide={!isMenuCollapsed}
                    // className="d-none"
                  >
                    <Link
                      to={`/reports/pending-guest-reviews`}
                      className={`${linkStyles} ${isMenuCollapsed &&
                        styles.collapsedLink}`}
                      getProps={isActive}
                      // state={{ slug: report.slug }}
                      state={{ slug: '/pending-guest-reviews', scrollPosition }}
                    >
                      <div className="position-relative">
                        {isMenuCollapsed && <Zap size="15" />}
                        {!isMenuCollapsed && (
                          <span className="link-label pl-1">
                            Pending Guest Reviews
                          </span>
                        )}
                      </div>
                    </Link>
                  </Tooltip>
                </li>
                <li>
                  <Tooltip
                    label={`Guest Reviews`}
                    placement="right"
                    id={`gr-tooltip`}
                    hide={!isMenuCollapsed}
                    // className="d-none"
                  >
                    <Link
                      to={`/reports/guest-reviews`}
                      className={`${linkStyles} ${isMenuCollapsed &&
                        styles.collapsedLink}`}
                      getProps={isActive}
                      // state={{ slug: report.slug }}
                      state={{ slug: '/guest-reviews', scrollPosition }}
                    >
                      <div className="position-relative">
                        {isMenuCollapsed && <Zap size="15" />}
                        {!isMenuCollapsed && (
                          <span className="link-label pl-1">Guest Reviews</span>
                        )}
                      </div>
                    </Link>
                  </Tooltip>
                </li>
                <li>
                  <AlertsConsumer>
                    {({ state: alertsState, loading: alertsLoading }) => {
                      console.log('alertsState', alertsState);
                      const bookingWindowsReportAlerts =
                        alertsState.bookingWindowsReport &&
                        alertsState.bookingWindowsReport.count > 0
                          ? alertsState.bookingWindowsReport.count
                          : null;

                      const showIcon =
                        !isMenuCollapsed || (isMenuCollapsed && !alertsLoading);
                      return (
                        <Tooltip
                          label={`Booking Windows ${bookingWindowsReportAlerts &&
                            `(${bookingWindowsReportAlerts} Alerts)`}`}
                          placement="right"
                          id={`bookingwindows-tooltip`}
                          hide={!isMenuCollapsed}
                          // className="d-none"
                        >
                          <Link
                            // title="Guest Relations"
                            onClick={() => {
                              document
                                .querySelector('body')
                                .classList.remove('offcanvas-open');
                            }}
                            className={`${linkStyles} ${isMenuCollapsed &&
                              styles.collapsedLink}`}
                            to="/reports/booking-windows"
                            getProps={isActive}
                            state={{ isMenuCollapsed, scrollPosition }}
                          >
                            <div className="position-relative">
                              {isMenuCollapsed && <Zap size="15" />}
                              <AlertsLoading
                                isLoading={isMenuCollapsed && alertsLoading}
                                position={'-2px'}
                              />
                              {!isMenuCollapsed && (
                                <span className=" pl-1">Booking Windows</span>
                              )}

                              {isMenuCollapsed &&
                                bookingWindowsReportAlerts &&
                                bookingWindowsReportAlerts > 0 && (
                                  <span className="badge badge-danger badge-collapsed ml-2 position-absolute"></span>
                                )}

                              {bookingWindowsReportAlerts &&
                                bookingWindowsReportAlerts > 0 && (
                                  <span className="badge badge-danger ml-2">
                                    {bookingWindowsReportAlerts}
                                  </span>
                                )}
                              <AlertsLoading
                                isLoading={!isMenuCollapsed && alertsLoading}
                              />
                            </div>
                          </Link>
                        </Tooltip>
                      );
                    }}
                  </AlertsConsumer>
                </li>
                <li>
                  <Tooltip
                    label={`Recent Bookings`}
                    placement="right"
                    id={`rb-tooltip`}
                    hide={!isMenuCollapsed}
                    // className="d-none"
                  >
                    <Link
                      to={`/reports/recent-bookings`}
                      className={`${linkStyles} ${isMenuCollapsed &&
                        styles.collapsedLink}`}
                      getProps={isActive}
                      // state={{ slug: report.slug }}
                      state={{ slug: '/recent-bookings', scrollPosition }}
                    >
                      <div className="position-relative">
                        {isMenuCollapsed && <Zap size="15" />}
                        {!isMenuCollapsed && (
                          <span className="link-label pl-1">
                            Recent Bookings
                          </span>
                        )}
                      </div>
                    </Link>
                  </Tooltip>
                </li>
                <li>
                  <Tooltip
                    label={`Overdue Reservations`}
                    placement="right"
                    id={`cpc-tooltip`}
                    hide={!isMenuCollapsed}
                    // className="d-none"
                  >
                    <Link
                      to={`/reports/overdue-reservations`}
                      className={`${linkStyles} ${isMenuCollapsed &&
                        styles.collapsedLink}`}
                      getProps={isActive}
                      // state={{ slug: report.slug }}
                      state={{
                        slug: '/reports/overdue-reservations',
                        scrollPosition,
                      }}
                    >
                      <div className="position-relative">
                        {isMenuCollapsed && <Zap size="15" />}
                        {!isMenuCollapsed && (
                          <span className="link-label pl-1">
                            Overdue Reservations
                          </span>
                        )}
                      </div>
                    </Link>
                  </Tooltip>
                </li>

                <li>
                  <Tooltip
                    label={`Unconfirmed 30/30`}
                    placement="right"
                    id={`uc-tooltip`}
                    hide={!isMenuCollapsed}
                    // className="d-none"
                  >
                    <Link
                      to={`/reports/unconfirmed-3030`}
                      className={`${linkStyles} ${isMenuCollapsed &&
                        styles.collapsedLink}`}
                      getProps={isActive}
                      // state={{ slug: report.slug }}
                      state={{ slug: '/unconfirmed-3030', scrollPosition }}
                    >
                      <div className="position-relative">
                        {isMenuCollapsed && <Zap size="15" />}
                        {!isMenuCollapsed && (
                          <span className="link-label pl-1">
                            Unconfirmed 30/30
                          </span>
                        )}
                      </div>
                    </Link>
                  </Tooltip>
                </li>
                <li>
                  <Tooltip
                    label={`TYFR Responses`}
                    placement="right"
                    id={`uc-tooltip`}
                    hide={!isMenuCollapsed}
                    // className="d-none"
                  >
                    <Link
                      to={`/reports/tyfr`}
                      className={`${linkStyles} ${isMenuCollapsed &&
                        styles.collapsedLink}`}
                      getProps={isActive}
                      // state={{ slug: report.slug }}
                      state={{ slug: '/tyfr', scrollPosition }}
                    >
                      <div className="position-relative">
                        {isMenuCollapsed && <Zap size="15" />}
                        {!isMenuCollapsed && (
                          <span className="link-label pl-1">
                            TYFR Responses
                          </span>
                        )}
                      </div>
                    </Link>
                  </Tooltip>
                </li>

                <li>
                  <Tooltip
                    label={`Upcoming Checkins`}
                    placement="right"
                    id={`uc-tooltip`}
                    hide={!isMenuCollapsed}
                    // className="d-none"
                  >
                    <Link
                      to={`/reports/upcoming-checkins`}
                      className={`${linkStyles} ${isMenuCollapsed &&
                        styles.collapsedLink}`}
                      getProps={isActive}
                      // state={{ slug: report.slug }}
                      state={{ slug: '/upcoming-checkins', scrollPosition }}
                    >
                      <div className="position-relative">
                        {isMenuCollapsed && <Zap size="15" />}
                        {!isMenuCollapsed && (
                          <span className="link-label pl-1">
                            Upcoming Checkins
                          </span>
                        )}
                      </div>
                    </Link>
                  </Tooltip>
                </li>
                <li>
                  <Tooltip
                    label={`Calendar Alerts`}
                    placement="right"
                    id={`cpc-tooltip`}
                    hide={!isMenuCollapsed}
                    // className="d-none"
                  >
                    <Link
                      to={`/reports/calendar-alerts`}
                      className={`${linkStyles} ${isMenuCollapsed &&
                        styles.collapsedLink}`}
                      getProps={isActive}
                      // state={{ slug: report.slug }}
                      state={{
                        slug: '/reports/calendar-alerts',
                        scrollPosition,
                      }}
                    >
                      <div className="position-relative">
                        {isMenuCollapsed && <Zap size="15" />}
                        {!isMenuCollapsed && (
                          <span className="link-label pl-1">
                            Calendar Alerts
                          </span>
                        )}
                      </div>
                    </Link>
                  </Tooltip>
                </li>
                <li>
                  <Tooltip
                    label={`Non-Standard CI/CO Times`}
                    placement="right"
                    id={`cpc-tooltip`}
                    hide={!isMenuCollapsed}
                    // className="d-none"
                  >
                    <Link
                      to={`/reports/non-standard-ci-co`}
                      className={`${linkStyles} ${isMenuCollapsed &&
                        styles.collapsedLink}`}
                      getProps={isActive}
                      // state={{ slug: report.slug }}
                      state={{ slug: '/non-standard-ci-co', scrollPosition }}
                    >
                      <div className="position-relative">
                        {isMenuCollapsed && <Zap size="15" />}
                        {!isMenuCollapsed && (
                          <span className="link-label pl-1">
                            Non-Standard CI/CO Times
                          </span>
                        )}
                      </div>
                    </Link>
                  </Tooltip>
                </li>
                <li>
                  <Tooltip
                    label={`Date Squatters`}
                    placement="right"
                    id={`ds-tooltip`}
                    hide={!isMenuCollapsed}
                    // className="d-none"
                  >
                    <Link
                      to={`/reports/date-squatters`}
                      className={`${linkStyles} ${isMenuCollapsed &&
                        styles.collapsedLink}`}
                      getProps={isActive}
                      // state={{ slug: report.slug }}
                      state={{ slug: '/date-squatters', scrollPosition }}
                    >
                      <div className="position-relative">
                        {isMenuCollapsed && <Zap size="15" />}
                        {!isMenuCollapsed && (
                          <span className="link-label pl-1">
                            Date Squatters
                          </span>
                        )}
                      </div>
                    </Link>
                  </Tooltip>
                </li>
                <li>
                  <Tooltip
                    label={`Zombie Reservations`}
                    placement="right"
                    id={`-tooltip`}
                    hide={!isMenuCollapsed}
                    // className="d-none"
                  >
                    <Link
                      to={`/reports/zombie-reservations`}
                      className={`${linkStyles} ${isMenuCollapsed &&
                        styles.collapsedLink}`}
                      getProps={isActive}
                      // state={{ slug: report.slug }}
                      state={{ slug: '/zombie-reservations', scrollPosition }}
                    >
                      <div className="position-relative">
                        {isMenuCollapsed && <Zap size="15" />}
                        {!isMenuCollapsed && (
                          <span className="link-label pl-1">
                            Zombie Reservations
                          </span>
                        )}
                      </div>
                    </Link>
                  </Tooltip>
                </li>

                <li>
                  <Tooltip
                    label={`Employee Change Log`}
                    placement="right"
                    id={`ds-tooltip`}
                    hide={!isMenuCollapsed}
                    // className="d-none"
                  >
                    <Link
                      to={`/reports/employee-change-log`}
                      className={`${linkStyles} ${isMenuCollapsed &&
                        styles.collapsedLink}`}
                      getProps={isActive}
                      // state={{ slug: report.slug }}
                      state={{ slug: '/employee-change-log', scrollPosition }}
                    >
                      <div className="position-relative">
                        {isMenuCollapsed && <Zap size="15" />}
                        {!isMenuCollapsed && (
                          <span className="link-label pl-1">
                            Employee Change Log
                          </span>
                        )}
                      </div>
                    </Link>
                  </Tooltip>
                </li>

                
                <li>
                  <Tooltip
                    label={`VRP Booking History`}
                    placement="right"
                    id={`ds-tooltip`}
                    hide={!isMenuCollapsed}
                    // className="d-none"
                  >
                    <Link
                      to={`/reports/vrp-booking-history`}
                      className={`${linkStyles} ${isMenuCollapsed &&
                        styles.collapsedLink}`}
                      getProps={isActive}
                      // state={{ slug: report.slug }}
                      state={{ slug: '/vrp-booking-history', scrollPosition }}
                    >
                      <div className="position-relative">
                        {isMenuCollapsed && <Zap size="15" />}
                        {!isMenuCollapsed && (
                          <span className="link-label pl-1">
                            VRP Booking History
                          </span>
                        )}
                      </div>
                    </Link>
                  </Tooltip>
                </li>

              </ul>
            </div>
          </div>
        </div>
        <div className="_card">
          {!isMenuCollapsed && (
            <div className="_card-header" id="headingThree">
              <h5 className="mb-0">
                <button
                  className="navAccordionLink  navAccordionLink  btn btn-link pl-1 pt-2 pb-0 d-flex align-items-center"
                  data-toggle="collapse"
                  data-target="#collapseThree"
                  aria-expanded="false"
                  aria-controls="collapseThree"
                  style={{ color: '#212529', fontSize: '.8rem' }}
                >
                  Reports
                </button>
              </h5>
            </div>
          )}
          <div
            id="collapseThree"
            className={`collapse show ${isMenuCollapsed && 'show'}`}
            aria-labelledby="headingThree"
          >
            <div className="_card-body">
              <ul
                className={`${styles.listUnstyled} list-unstyled components small`}
              >
                {/* {!isMenuCollapsed && (
                  <li className={`${styles.navTitle} mt-3 pl-3 pt-2 pb-2`}>
                    Reports
                  </li>
                )} */}
                <li>
                  <AlertsConsumer>
                    {({ state: alertsState, loading: alertsLoading }) => {
                      const airbnb3NightGapAlerts =
                        alertsState.airbnb3NightGapReport &&
                        alertsState.airbnb3NightGapReport.count > 0
                          ? alertsState.airbnb3NightGapReport.count
                          : null;

                      const showIcon =
                        !isMenuCollapsed || (isMenuCollapsed && !alertsLoading);
                      return (
                        <Tooltip
                          label={`Airbnb 3-Night Gap ${airbnb3NightGapAlerts &&
                            `(${airbnb3NightGapAlerts} Alerts)`}`}
                          placement="right"
                          id={`airbnb3nightgap-tooltip`}
                          hide={!isMenuCollapsed}
                          // className="d-none"
                        >
                          <Link
                            // title="Guest Relations"
                            onClick={() => {
                              document
                                .querySelector('body')
                                .classList.remove('offcanvas-open');
                            }}
                            className={`${linkStyles} ${isMenuCollapsed &&
                              styles.collapsedLink}`}
                            to="/reports/airbnb-3-night-gap"
                            getProps={isActive}
                            state={{ isMenuCollapsed, scrollPosition }}
                          >
                            <div className="position-relative">
                              {isMenuCollapsed && <Zap size="15" />}
                              <AlertsLoading
                                isLoading={isMenuCollapsed && alertsLoading}
                                position={'-2px'}
                              />
                              {!isMenuCollapsed && (
                                <span className=" pl-1">
                                  Airbnb 3-Night Gap
                                </span>
                              )}

                              {isMenuCollapsed &&
                                airbnb3NightGapAlerts &&
                                airbnb3NightGapAlerts > 0 && (
                                  <span className="badge badge-danger badge-collapsed ml-2 position-absolute"></span>
                                )}

                              {airbnb3NightGapAlerts &&
                                airbnb3NightGapAlerts > 0 && (
                                  <span className="badge badge-danger ml-2">
                                    {airbnb3NightGapAlerts}
                                  </span>
                                )}
                              <AlertsLoading
                                isLoading={!isMenuCollapsed && alertsLoading}
                              />
                            </div>
                          </Link>
                        </Tooltip>
                      );
                    }}
                  </AlertsConsumer>
                </li>



                <li>
                  <Tooltip
                    label={`Combo Price Check`}
                    placement="right"
                    id={`cpc-tooltip`}
                    hide={!isMenuCollapsed}
                    // className="d-none"
                  >
                    <Link
                      to={`/reports/combo-price-check`}
                      className={`${linkStyles} ${isMenuCollapsed &&
                        styles.collapsedLink}`}
                      getProps={isActive}
                      // state={{ slug: report.slug }}
                      state={{ slug: '/combo-price-check', scrollPosition }}
                    >
                      <div className="position-relative">
                        {isMenuCollapsed && <Zap size="15" />}
                        {!isMenuCollapsed && (
                          <span className="link-label pl-1">
                            Combo Price Check
                          </span>
                        )}
                      </div>
                    </Link>
                  </Tooltip>
                </li>

                <li>
                  <Tooltip
                    label={`Upcoming Invoices`}
                    placement="right"
                    id={`upcoming-invoices-tooltip`}
                    hide={!isMenuCollapsed}
                    // className="d-none"
                  >
                    <Link
                      to={`/reports/upcoming-invoices`}
                      className={`${linkStyles} ${isMenuCollapsed &&
                        styles.collapsedLink}`}
                      getProps={isActive}
                      // state={{ slug: report.slug }}
                      state={{ slug: '/upcoming-invoices', scrollPosition }}
                    >
                      <div className="position-relative">
                        {isMenuCollapsed && <Zap size="15" />}
                        {!isMenuCollapsed && (
                          <span className="link-label pl-1">
                            Upcoming Invoices
                          </span>
                        )}
                      </div>
                    </Link>
                  </Tooltip>
                </li>

                <li>
                  <AlertsConsumer>
                    {({ state: alertsState, loading: alertsLoading }) => {
                      const propertyVacanciesAlerts =
                        alertsState.propertyVacancies &&
                        alertsState.propertyVacancies.length > 0
                          ? alertsState.propertyVacancies.length
                          : null;
                      const showIcon =
                        !isMenuCollapsed || (isMenuCollapsed && !alertsLoading);
                      return (
                        <>
                          <Tooltip
                            label={`Occupancy Analyzer ${propertyVacanciesAlerts &&
                              `(${propertyVacanciesAlerts} Alerts)`}`}
                            placement="right"
                            id={`oa-tooltip`}
                            hide={!isMenuCollapsed}
                            // className="d-none"
                          >
                            <Link
                              // title={'Occupancy Analyzer'}
                              to={`/reports/occupancy-analyzer`}
                              className={`${linkStyles} ${isMenuCollapsed &&
                                styles.collapsedLink}`}
                              getProps={isActive}
                              state={{ isMenuCollapsed, scrollPosition }}
                            >
                              <div className="position-relative">
                                {isMenuCollapsed && (
                                  <Activity
                                    className={`${!showIcon &&
                                      alertsLoading &&
                                      'invisible'}`}
                                    size="15"
                                  />
                                )}
                                <AlertsLoading
                                  isLoading={isMenuCollapsed && alertsLoading}
                                  position={'-2px'}
                                />
                                {!isMenuCollapsed && (
                                  <span className="link-label pl-1">
                                    Occupancy Analyzer
                                  </span>
                                )}
                                {isMenuCollapsed &&
                                  alertsState.propertyVacancies &&
                                  alertsState.propertyVacancies.length > 0 && (
                                    <span className="badge badge-danger badge-collapsed ml-2 position-absolute"></span>
                                  )}

                                {alertsState.propertyVacancies &&
                                  alertsState.propertyVacancies.length > 0 && (
                                    <span className="badge badge-danger ml-2">
                                      {alertsState.propertyVacancies.length}
                                    </span>
                                  )}
                                <AlertsLoading
                                  isLoading={!isMenuCollapsed && alertsLoading}
                                />
                              </div>
                            </Link>
                          </Tooltip>
                        </>
                      );
                    }}
                  </AlertsConsumer>
                </li>
{/* 
                <li>
                  <AlertsConsumer>
                    {({ state: alertsState, loading: alertsLoading }) => {
                      const propertyVacancyFragmentationReportAlerts =
                        alertsState.propertyVacancyFragmentationReport &&
                        alertsState.propertyVacancyFragmentationReport.length > 0
                          ? alertsState.propertyVacancyFragmentationReport.length
                          : null;
                      const showIcon =
                        !isMenuCollapsed || (isMenuCollapsed && !alertsLoading);
                      return (
                        <>
                          <Tooltip
                            label={`Fragmentation Report ${propertyVacancyFragmentationReportAlerts &&
                              `(${propertyVacancyFragmentationReportAlerts} Alerts)`}`}
                            placement="right"
                            id={`oa-tooltip`}
                            hide={!isMenuCollapsed}
                            // className="d-none"
                          >
                            <Link
                              // title={'Occupancy Analyzer'}
                              to={`/reports/fragmentation-report`}
                              className={`${linkStyles} ${isMenuCollapsed &&
                                styles.collapsedLink}`}
                              getProps={isActive}
                              state={{ isMenuCollapsed, scrollPosition }}
                            >
                              <div className="position-relative">
                                {isMenuCollapsed && (
                                  <Activity
                                    className={`${!showIcon &&
                                      alertsLoading &&
                                      'invisible'}`}
                                    size="15"
                                  />
                                )}
                                <AlertsLoading
                                  isLoading={isMenuCollapsed && alertsLoading}
                                  position={'-2px'}
                                />
                                {!isMenuCollapsed && (
                                  <span className="link-label pl-1">
                                    Fragmentation Rpt
                                  </span>
                                )}
                                {isMenuCollapsed &&
                                  alertsState.propertyVacancyFragmentationReport &&
                                  alertsState.propertyVacancyFragmentationReport.length > 0 && (
                                    <span className="badge badge-danger badge-collapsed ml-2 position-absolute"></span>
                                  )}

                                {alertsState.propertyVacancyFragmentationReport &&
                                  alertsState.propertyVacancyFragmentationReport.length > 0 && (
                                    <span className="badge badge-danger ml-2">
                                      {alertsState.propertyVacancyFragmentationReport.length}
                                    </span>
                                  )}
                                <AlertsLoading
                                  isLoading={!isMenuCollapsed && alertsLoading}
                                />
                              </div>
                            </Link>
                          </Tooltip>
                        </>
                      );
                    }}
                  </AlertsConsumer>
                </li> */}
                <li>
                  <AlertsConsumer>
                    {({ state: alertsState, loading: alertsLoading }) => {
                      const unavailableDatesAlerts =
                        alertsState.unavailableDatesReport &&
                        alertsState.unavailableDatesReport.count > 0
                          ? alertsState.unavailableDatesReport.count
                          : null;

                      const showIcon =
                        !isMenuCollapsed || (isMenuCollapsed && !alertsLoading);
                      return (
                        <Tooltip
                          label={`Unavailable Date Report ${unavailableDatesAlerts &&
                            `(${unavailableDatesAlerts} Alerts)`}`}
                          placement="right"
                          id={`unavailableDates-tooltip`}
                          hide={!isMenuCollapsed}
                          // className="d-none"
                        >
                          <Link
                            // title="Guest Relations"
                            onClick={() => {
                              document
                                .querySelector('body')
                                .classList.remove('offcanvas-open');
                            }}
                            className={`${linkStyles} ${isMenuCollapsed &&
                              styles.collapsedLink}`}
                            to="/reports/unavailable-dates"
                            getProps={isActive}
                            state={{ isMenuCollapsed, scrollPosition }}
                          >
                            <div className="position-relative">
                              {isMenuCollapsed && <Zap size="15" />}
                              <AlertsLoading
                                isLoading={isMenuCollapsed && alertsLoading}
                                position={'-2px'}
                              />
                              {!isMenuCollapsed && (
                                <span className=" pl-1">Unavailable Dates</span>
                              )}

                              {isMenuCollapsed &&
                                unavailableDatesAlerts &&
                                unavailableDatesAlerts > 0 && (
                                  <span className="badge badge-danger badge-collapsed ml-2 position-absolute"></span>
                                )}

                              {unavailableDatesAlerts &&
                                unavailableDatesAlerts > 0 && (
                                  <span className="badge badge-danger ml-2">
                                    {unavailableDatesAlerts}
                                  </span>
                                )}
                              <AlertsLoading
                                isLoading={!isMenuCollapsed && alertsLoading}
                              />
                            </div>
                          </Link>
                        </Tooltip>
                      );
                    }}
                  </AlertsConsumer>
                </li>
                <li>
                  <AlertsConsumer>
                    {({ state: alertsState, loading: alertsLoading }) => {
                      console.log('alertsState1', alertsState);
                      const propertyRateAlertsReportAlerts =
                        alertsState.propertyRateAlertsReport &&
                        alertsState.propertyRateAlertsReport.count > 0
                          ? alertsState.propertyRateAlertsReport.count
                          : null;
                      console.log(
                        'propertyRateAlertsReportAlerts',
                        propertyRateAlertsReportAlerts
                      );
                      const showIcon =
                        !isMenuCollapsed || (isMenuCollapsed && !alertsLoading);
                      return (
                        <Tooltip
                          label={`Property Rate Alerts ${propertyRateAlertsReportAlerts &&
                            `(${propertyRateAlertsReportAlerts} Alerts)`}`}
                          placement="right"
                          id={`propertyRateAlerts-tooltip`}
                          hide={!isMenuCollapsed}
                          // className="d-none"
                        >
                          <Link
                            // title="Guest Relations"
                            onClick={() => {
                              document
                                .querySelector('body')
                                .classList.remove('offcanvas-open');
                            }}
                            className={`${linkStyles} ${isMenuCollapsed &&
                              styles.collapsedLink}`}
                            to="/reports/property-rate-alerts"
                            getProps={isActive}
                            state={{ isMenuCollapsed, scrollPosition }}
                          >
                            <div className="position-relative">
                              {isMenuCollapsed && <Zap size="15" />}
                              <AlertsLoading
                                isLoading={isMenuCollapsed && alertsLoading}
                                position={'-2px'}
                              />
                              {!isMenuCollapsed && (
                                <span className=" pl-1">
                                  Property Rate Alerts
                                </span>
                              )}

                              {isMenuCollapsed &&
                                propertyRateAlertsReportAlerts &&
                                propertyRateAlertsReportAlerts > 0 && (
                                  <span className="badge badge-danger badge-collapsed ml-2 position-absolute"></span>
                                )}

                              {propertyRateAlertsReportAlerts &&
                                propertyRateAlertsReportAlerts > 0 && (
                                  <span className="badge badge-danger ml-2">
                                    {propertyRateAlertsReportAlerts}
                                  </span>
                                )}
                              <AlertsLoading
                                isLoading={!isMenuCollapsed && alertsLoading}
                              />
                            </div>
                          </Link>
                        </Tooltip>
                      );
                    }}
                  </AlertsConsumer>
                </li>
                <li>
                <Tooltip
                          label={`Available Date Report`}
                          placement="right"
                          id={`availableDates-tooltip`}
                          hide={!isMenuCollapsed}
                          // className="d-none"
                        >
                          <Link
                            // title="Guest Relations"
                            onClick={() => {
                              document
                                .querySelector('body')
                                .classList.remove('offcanvas-open');
                            }}
                            className={`${linkStyles} ${isMenuCollapsed &&
                              styles.collapsedLink}`}
                            to="/reports/available-dates"
                            getProps={isActive}
                            state={{ isMenuCollapsed, scrollPosition }}
                          >
                            <div className="position-relative">
                              {isMenuCollapsed && <Zap size="15" />}
                              <AlertsLoading
                                isLoading={isMenuCollapsed && alertsLoading}
                                position={'-2px'}
                              />
                              {!isMenuCollapsed && (
                                <span className=" pl-1">Available Dates</span>
                              )}

                         
                            </div>
                          </Link>
                        </Tooltip>
                </li>
                <li>
                  <Tooltip
                    label={`VRP Price Check`}
                    placement="right"
                    id={`-tooltip`}
                    hide={!isMenuCollapsed}
                    // className="d-none"
                  >
                    <Link
                      to={`/reports/vrp-price-check`}
                      className={`${linkStyles} ${isMenuCollapsed &&
                        styles.collapsedLink}`}
                      getProps={isActive}
                      // state={{ slug: report.slug }}
                      state={{ slug: '/vrp-price-check', scrollPosition }}
                    >
                      <div className="position-relative">
                        {isMenuCollapsed && <Zap size="15" />}
                        {!isMenuCollapsed && (
                          <span className="link-label pl-1">Price Manager</span>
                        )}
                      </div>
                    </Link>
                  </Tooltip>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      {/* <ul className={`${styles.listUnstyled} list-unstyled components small`}>
        {!isMenuCollapsed && (
          <li className={`${styles.navTitle} mt-3 pl-3 pt-2 pb-2`}>
            Dynamic Reports
          </li>
        )}
        {!userAuthenticated && !isMenuCollapsed && (
          <span className="ml-4">Login required</span>
        )}

        {loading && (
          <div className="d-flex justify-content-center align-items-center mt-3">
            <LoadingSpinner />
          </div>
        )}

        {userAuthenticated && reportRoutes && !loading
          ? reportRoutes.map((report, index) => {
              // const linkTarget = {
              //   pathname: `${report.slug}`,
              //   key: Math.random()
              // }
              return (
                <li key={index}>
                  <Tooltip
                    label={`${report?.label}`}
                    placement="right"
                    id={`${report?.slug}`}
                    hide={!isMenuCollapsed}
                    // className="d-none"
                  >
                    <Link
                      to={`${report.slug}`}
                      className={`${linkStyles} ${isMenuCollapsed &&
                        styles.collapsedLink}`}
                      getProps={isActive}
                      state={{ slug: report.slug }}
                    >
                      <div className="position-relative">
                        {isMenuCollapsed && <Zap size="15" />}
                        {!isMenuCollapsed && (
                          <span className="link-label pl-1">
                            {report.label}
                          </span>
                        )}
                      </div>
                    </Link>
                  </Tooltip>
                </li>
              );
            })
          : null}
      </ul> */}
    </>
  );
};

export default NavMenu;
