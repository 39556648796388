import React, { useState } from 'react';

import { useMutation } from 'react-apollo';
import get from 'lodash/get';
import Modal from 'src/components/Modal';
import LoadingSpinner from 'src/components/LoadingSpinner';
import QuoteGenerator from 'src/components/QuoteGenerator';
import Query from 'src/components/GraphQL/components/Query';
import gql from 'graphql-tag';
import YouTube from 'src/images/youtube.svg';
import MatterPort from 'src/images/matterport.svg';
import './styles.scss';


const SCRAPE_VRP_DATA = gql`
  mutation ScrapeMasterPlatformData($propertyKey: String!) {
    scrapeMasterPlatformData(
      data: { propertyKey: $propertyKey }
    ) {
      job {
        id
        status
      }
    }
  }
`;

const PROPERTY_DETAILS = gql`
  query Property($id: Int) {
    property(where: { id: $id }) {
      name
      key
      label
      id
      bedrooms
      bathrooms
      sleeps
      maxAdultComfort
      description
      addressDisplay
      virtualTours {
        url
      }
      sitePage {
        excerpt
        featuredFiles {
          file {
            fullUrl
            mimetype
          }
        }
        featuredImage {
          file {
            medium: version(where: { key: "medium" }) {
              fullUrl
            }
          }
        }
      }
      preferredPropertyListing {
        propertyUrl
        platform {
          name
        }
      }
      propertyListings {
        propertyUrl
        platform {
          key
          name
        }
      }
    }
  }
`;

const PropertyDetailsFooter = ({ targetProperty }) => {
  return (
    <div className="d-block w-100">
      <div
        id="property-details-modal-footer"
        className="mb-2 d-flex justify-content-between w-100 text-center"
      >
        <div className="font-weight-bold">
          {targetProperty.bedrooms} Bedrooms
        </div>

        <div className="font-weight-bold">{targetProperty.bathrooms} Baths</div>

        <div className="font-weight-bold">{targetProperty.sleeps} Guests</div>
        <div className="font-weight-bold">
          {targetProperty.maxAdultComfort} Adults Comfort
        </div>
      </div>
      {targetProperty?.sitePage?.excerpt}
    </div>
  );
};

const PropertyDetailsModal = ({ propertyId, onResetId }) => {
  const [isQuoteGeneratorModalOpen, setIsQuoteGeneratorModalOpen] = useState(
    false
  );
  const [fetchVRPModalOpen, setFetchVRPModalOpen] = useState(
    false
  );
  const [scrapeData, { data, loading, error }] = useMutation(
    SCRAPE_VRP_DATA
    );
  const propertyDetailsModalIsOpen = propertyId ? true : false;
  return (
    <Query
      variables={{
        id: propertyId,
      }}
      query={PROPERTY_DETAILS}
      skip={!propertyId}
      //   fetchPolicy={'no-cache'}
    >
      {({ loading, error, data }) => {
        if (loading)
          return (
            <div className="container pt-3">
              <div
                className="d-flex justify-content-center align-items-center"
                style={{ height: '80vh' }}
              >
                <LoadingSpinner />
              </div>
            </div>
          );

        if (error)
          return (
            <div className="container-fluid pt-3">
              {error.graphQLErrors[0].message}
            </div>
          );
        const targetProperty = get(data, 'property') || null;
        const matterPortLink =
          get(data, 'property.virtualTours[0].url') || null;
        const featuredFiles =
          get(data, 'property.sitePage.featuredFiles') || [];
        const youTubeFile = featuredFiles.find(
          featuredFile => (featuredFile.file || {}).mimetype === 'video/youtube'
        );
        const youTubeLink = youTubeFile ? youTubeFile.file.fullUrl : null;

        const fullUrl = targetProperty
          ? get(targetProperty, 'sitePage.featuredImage.file.medium.fullUrl') ||
            'https://via.placeholder.com/466x349'
          : 'https://via.placeholder.com/466x349';
        const homeAwayPropertyListingData =
          (targetProperty &&
            targetProperty.propertyListings.find(
              channel => channel.platform.key === 'homeaway'
            )) ||
          {};

        const preferredPropertyListing =
          targetProperty && targetProperty.preferredPropertyListing
            ? targetProperty.preferredPropertyListing
            : {};
        return (
          <>
            <Modal
              title={
                targetProperty ? (
                  <div className="mt-2">
                    <p className="mb-0">
                      {targetProperty.label} - {targetProperty.name}
                    </p>
                    <div className="w-100">
                      <div className="d-flex justify-content-between">
                        <div style={{ maxWidth: '90%' }}>
                          <p className="mb-0">
                            {targetProperty.addressDisplay === ', ,  ' ? (
                              <span className="invisible">N/A</span>
                            ) : (
                              targetProperty.addressDisplay
                            )}
                          </p>
                        </div>
                        <div
                          className="position-absolute"
                          // style={{ right: '17px' }}
                          style={{ right: '0px' }}
                        >
                          {youTubeLink && (
                            <a
                              className="mr-1"
                              target="_blank"
                              title="YouTube"
                              href={youTubeLink}
                            >
                              <img width="20" src={YouTube} />
                            </a>
                          )}
                          {matterPortLink && (
                            <a
                              target="_blank"
                              title="3D Tour"
                              href={matterPortLink}
                            >
                              <img width="20" src={MatterPort} />
                            </a>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  ''
                )
              }
              isOpen={propertyDetailsModalIsOpen}
              onClose={() => onResetId(false)}
              footerContent={
                targetProperty && (
                  <PropertyDetailsFooter targetProperty={targetProperty} />
                )
              }
              customBodyStyles={'pb-0'}
            >
              <div className="w-100">
                <img className="img-fluid" src={fullUrl} />
                <a
                  href={preferredPropertyListing.propertyUrl}
                  className={`w-100 mt-3 btn btn-outline-primary book-property-button font-weight-bold ${
                    preferredPropertyListing.propertyUrl ? '' : 'disabled'
                  }`}
                  target="_blank"
                >
                  {preferredPropertyListing.propertyUrl
                    ? `Book this property on ${
                        (preferredPropertyListing.platform || {}).name
                      }`
                    : 'Book this property'}
                </a>
                {/* <button
                  className="btn btn-outline-primary w-100 font-weight-bold mt-2"
                  onClick={() => setIsQuoteGeneratorModalOpen(true)}
                >
                  Generate Quote (experimental)
                </button> */}
                <button
                  className="btn btn-outline-primary w-100 font-weight-bold mt-2"
                  onClick={() => setFetchVRPModalOpen(true)}
                >
                  Fetch VRP Admin Data
                </button>
              </div>
              <div className="container-fluid px-0">
                <div className="mt-3 w-100 row">
                  <a
                    className="d-inline-block ml-0 mr-0 mb-2 col col-4 pr-0"
                    key={(homeAwayPropertyListingData.platform || {}).name}
                    href={homeAwayPropertyListingData.propertyUrl}
                    target="_blank"
                  >
                    {(homeAwayPropertyListingData.platform || {}).name}
                  </a>
                  {targetProperty &&
                    targetProperty.propertyListings.reduce((acc, channel) => {
                      if (
                        channel.platform.key !== 'homeaway' &&
                        channel.propertyUrl
                      ) {
                        acc.push(
                          <a
                            className="d-inline-block ml-0 mr-0 mb-2 col col-4 pr-0"
                            key={channel.platform.name}
                            href={channel.propertyUrl}
                            target="_blank"
                          >
                            {channel.platform.name}
                          </a>
                        );
                      }
                      return acc;
                    }, [])}
                </div>
              </div>
            </Modal>
            <Modal
              styleType="large"
              isOpen={isQuoteGeneratorModalOpen}
              onClose={() => setIsQuoteGeneratorModalOpen(false)}
              disableForceFocus={true}
              title={targetProperty && targetProperty.name}
            >
              <QuoteGenerator
                propertyId={targetProperty && targetProperty.id}
              />
            </Modal>
             <Modal
              
              isOpen={fetchVRPModalOpen}
              onClose={() => setFetchVRPModalOpen(false)}
              disableForceFocus={true}
              title={'Fetch Data'}
            >
              VRP Admin data is retrieved every hour. Utilize this feature to retreive and import the data in less than 10 minutes.
              <button
                  className="btn btn-outline-primary w-100 font-weight-bold mt-2"
                  onClick={async () => {  
                    try {
                      
                      const result = await scrapeData({
                        variables: { propertyKey: targetProperty.key },
                      });
                      console.log('result', result);
                      if (
                        result?.data
                          ?.scrapeMasterPlatformData?.job?.status ===
                        'IN_QUEUE'
                      ) {
                        setFetchVRPModalOpen(false);
                      alert(`Property is in queue to be scraped. Please wait upwards of 10 minutes.`)
                      }
                    } catch (err) {
                      console.log('err', err);                      
                      if (Array.isArray(err.graphQLErrors)) {
                        if (err.graphQLErrors.length > 0) {
                          alert(err.graphQLErrors[0].message);
                        }
                      }
                    }
                  }}
                >
                  Fetch VRP Admin Data
                </button>
            </Modal>
          </>
        );
      }}
    </Query>
  );
};
export default PropertyDetailsModal;
